import { css, styled } from '@ringcentral/juno';
import { Link } from 'react-router-dom';
import { focusOutline } from '@apw/lib/sc';

type FeatureItemProps = {
  extraStyles: string;
};

const FeatureItemCommon = css<FeatureItemProps>`
  display: block;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #e1e6ea;

  &:focus-visible {
    ${focusOutline};
  }

  ${({ extraStyles }) => extraStyles};
`;

export const FeatureLinkItem = styled.a<FeatureItemProps>`
  ${FeatureItemCommon};
`;

export const FeatureAppItem = styled(Link)<FeatureItemProps>`
  ${FeatureItemCommon};
`;

export const FeaturePageItem = styled(Link)<FeatureItemProps>`
  ${FeatureItemCommon};
`;

export const RatingContainer = styled.div`
  margin-top: auto;
  padding-top: 12px;
`;
