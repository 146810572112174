import React, { useState } from 'react';
import { RcIcon, RcTypography, RcVisuallyHidden } from '@ringcentral/juno';
import { Link } from 'react-router-dom';
import premierPartnerBadge from '@apw/lib/assets/images/premier-partner-badge.svg';
import { MultipleLineEllipsis } from '@apw/lib/components/multipleLineEllipsis';
import {
  ProfileLogo,
  ProfileName,
} from '@apw/lib/components/profileTile/shared';
import { RatingInfo } from '@apw/lib/components/ratingAndReview/ratingInfo/RatingInfo';
import {
  IContentBlockTypography,
  IProfileInContentBlock,
} from '@apw/lib/types';
import { CertifiedBadge } from '../certifiedBadge';
import styles from './profileTile.module.scss';
import {
  ContentWrapper,
  ContentWrapperShadow,
  InstallTrigger,
  RatingWrapper,
} from './profileTile.sc';

export interface ProfileTileProps {
  app: IProfileInContentBlock;
  handleClick?: () => void;
  styleTokens: {
    name: IContentBlockTypography;
    publisher: IContentBlockTypography;
    premierPartner: IContentBlockTypography;
    certifiedBadge: string;
    shortDescription: IContentBlockTypography;
    backgroundColor: string;
    backgroundHoverColor: string;
    borderColor: string;
    col: 'tileCol4' | 'tileCol3' | 'tileCol2' | 'tileCol1' | string;
  };
  certifiedBadgeText: string;
  logoAlt: string;
  premierPartnerText: string;
  countLabel: {
    singular: string;
    plural: string;
  };
}

export function ProfileTile(props: ProfileTileProps) {
  const {
    app,
    handleClick,
    styleTokens,
    certifiedBadgeText,
    logoAlt,
    premierPartnerText,
    countLabel,
  } = props;

  const [hoverTile, setHoverTile] = useState(false);

  const contentStyles = {
    backgroundColor: styleTokens.backgroundColor,
    borderColor: styleTokens.borderColor,
  };

  const contentWrapperProps: any = {
    component: app.path ? Link : 'div',
    ...(app.path && { to: app.path }),
  };

  const onTileClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  return (
    <div
      className={`${styles.wrapper} ${hoverTile ? styles.hoverTile : ''} ${
        styles[styleTokens.col || 'tileCol4']
      }`}
      data-test-automation-id={`app-tile-${
        app.application?.displayName || app.name
      }`}
    >
      <ContentWrapper {...contentWrapperProps} onClick={onTileClick}>
        <div className={styles.content} style={contentStyles}>
          <div className={styles.tileTop}>
            <div className={styles.appLogo}>
              <ProfileLogo profile={app} borderRadius={16} logoAlt={logoAlt} />
            </div>
            {app.application?.certifiedBadge && (
              <CertifiedBadge
                className={styles.certifiedBadge}
                color={styleTokens.certifiedBadge}
                text={certifiedBadgeText}
              />
            )}
          </div>
          <div className={styles.tileBottom}>
            <div className={styles.appInfo}>
              <ProfileName
                profile={app}
                className={styles.appName}
                styleTokens={styleTokens.name}
              />
              <div className={styles.appPublisher}>
                <RcTypography
                  component="span"
                  {...styleTokens.publisher}
                  className={styles.publisherName}
                  data-test-automation-id="app-tile-publisher-name"
                >
                  {app.publisher}
                </RcTypography>
                {app.organization?.partnerBadge === 'Premier' && (
                  <>
                    <RcIcon
                      className={`${styles.appPublisherIcon} premierPartnerIcon`}
                      symbol={premierPartnerBadge}
                      size="inherit"
                      color={styleTokens.premierPartner.color}
                      data-test-automation-id="app-tile-publisher-icon"
                    />
                    <RcVisuallyHidden>{premierPartnerText}</RcVisuallyHidden>
                  </>
                )}
              </div>
              <RcTypography
                component="div"
                {...styleTokens.shortDescription}
                className={styles.appDesc}
                title={app.shortDescription}
                data-test-automation-id="app-tile-desc"
              >
                <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                  {app.shortDescription}
                </MultipleLineEllipsis>
              </RcTypography>
              {app.application.appRatingEnabled && app.application.appRating && (
                <RatingWrapper
                  data-test-automation-id={'profile-tile-rating-wrapper'}
                >
                  <RatingInfo
                    rating={app.application.appRating.overallRating}
                    ratingCount={app.application.appRating.voteCount}
                    translation={{ countLabel }}
                  />
                </RatingWrapper>
              )}
            </div>
          </div>
        </div>
      </ContentWrapper>
      <ContentWrapperShadow
        className={styles.wrapperShadow}
        style={{ backgroundColor: styleTokens.backgroundHoverColor }}
      />
      {app.installTriggerRenderer?.({}) && (
        <InstallTrigger
          className={styles.installTrigger}
          data-test-automation-id="app-tile-install-trigger"
        >
          {app.installTriggerRenderer({
            focus: () => setHoverTile(true),
            blur: () => setHoverTile(false),
          })}
        </InstallTrigger>
      )}
    </div>
  );
}
