import { RcTypography, styled } from '@ringcentral/juno';
import { focusOutline } from '../../../sc';

export const Container = styled(RcTypography)`
  width: 100%;
  margin-bottom: 32px;
  overflow: auto;

  &:last-child {
    margin-bottom: 0;
  }

  a {
    &:not(:focus-visible) {
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus-visible {
      ${focusOutline};
    }
  }

  img {
    max-width: 100%;
  }
`;
