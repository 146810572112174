import React, { useContext, useMemo } from 'react';
import { RcIcon } from '@ringcentral/juno';
import { ExternalLink } from '@ringcentral/juno-icon';
import { useRouteMatch } from 'react-router-dom';
import defaultImage from '@apw/lib/assets/images/feature-card-placeholder.png';
import { MultipleLineEllipsis } from '@apw/lib/components/multipleLineEllipsis';
import { PageContentContext } from '@apw/lib/components/pageContent';
import { RatingInfo } from '@apw/lib/components/ratingAndReview/ratingInfo/RatingInfo';
import {
  FeatureOptionEnum,
  FeatureTypeEnum,
  IFeatureApp,
  IFeatureBase,
  IFeatureLink,
  IFeaturePage,
} from '@apw/lib/types';
import styles from '../featureBlock.module.scss';
import {
  FeatureAppItem,
  FeatureLinkItem,
  FeaturePageItem,
  RatingContainer,
} from './featureItem.sc';

export const computedBgColor = (feature: IFeatureApp) => {
  return feature.featureType === FeatureTypeEnum.App &&
    feature.imageOption === FeatureOptionEnum.Default
    ? feature.color
    : 'transparent';
};

function FeatureItem({ item }: { item: IFeatureBase }) {
  const { url } = useRouteMatch();
  const featureMap = {
    [FeatureTypeEnum.Link]: (item: IFeatureLink) => ({
      tag: FeatureLinkItem,
      path: item.otherLink,
      target: '_blank',
    }),
    [FeatureTypeEnum.App]: (item: IFeatureApp) => ({
      tag: FeatureAppItem,
      path: `${url}?appid=${item.appId}`,
      target: '_self',
    }),
    [FeatureTypeEnum.Page]: (item: IFeaturePage) => ({
      tag: FeaturePageItem,
      path: item.page ? item.page.vanityUrl : '',
      target: '_self',
    }),
  };

  const backgroundImage = useMemo(() => {
    if (item.imageOption === FeatureOptionEnum.Custom) {
      return item.image;
    }
    if (item.featureType === FeatureTypeEnum.App) {
      const feature = item as IFeatureApp;
      return feature.profile!.application.iconUri || defaultImage;
    }
    if (item.featureType === FeatureTypeEnum.Page) {
      const feature = item as IFeaturePage;
      return feature.page?.imageUrl || defaultImage;
    }
    return defaultImage;
  }, [item]);

  const backgroundSize = useMemo(() => {
    return item.featureType === FeatureTypeEnum.App &&
      item.imageOption === FeatureOptionEnum.Default
      ? 'contain'
      : 'cover';
  }, [item]);

  const backgroundColor = useMemo(() => {
    const feature = item as IFeatureApp;
    return computedBgColor(feature);
  }, [item]);

  // @ts-ignore
  const featureContainer = featureMap[item.featureType](item);
  const FeatureItemContainer = featureContainer.tag;
  const {
    styleTokens: { features },
    translation,
  } = useContext(PageContentContext);

  const imgAlt = useMemo(() => {
    if (item.featureType === FeatureTypeEnum.App) {
      const appItem = item as IFeatureApp;
      return translation.getAppLogo(
        appItem.profile?.application?.displayName || '',
      );
    }
    return translation.getImage(item.title);
  }, [item]);

  const ratingInfo = useMemo(() => {
    if (item.featureType === FeatureTypeEnum.App) {
      const appItem = item as IFeatureApp;
      const appRating = appItem.profile?.application?.appRating;
      if (appRating) {
        return {
          overallRating: appRating.overallRating,
          voteCount: appRating.voteCount,
        };
      }
    }
    return null;
  }, [item]);

  const appRatingEnabled = useMemo(() => {
    if (item.featureType === FeatureTypeEnum.App) {
      const appItem = item as IFeatureApp;
      return appItem.profile?.application?.appRatingEnabled;
    }
    return false;
  }, [item]);

  return (
    <div className={styles.featureItemContainer}>
      <FeatureItemContainer
        href={featureContainer.path}
        target={featureContainer.target}
        to={featureContainer.path!}
        className={styles.itemLink}
        extraStyles={features.container}
      >
        <div
          className={styles.mask}
          // @ts-ignore
          STYLE={features.mask}
        />
        <div
          className={styles.container}
          // @ts-ignore
          STYLE={features.content}
        >
          {item.sponsored && (
            <div className={styles.sponsored} style={features.sponsored}>
              {translation.sponsored}
            </div>
          )}
          <div className={styles.imageContainer}>
            <div
              className={styles.featureItemImage}
              data-testid="featureItemImage"
              style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize,
                backgroundColor,
              }}
              role="img"
              aria-label={imgAlt}
            />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.featureItemTitleContainer}>
              <div
                className={styles.featureItemTitle}
                title={item.title}
                // @ts-ignore
                STYLE={features.item.title}
              >
                {item.title}
              </div>
              {item.featureType === FeatureTypeEnum.Link && (
                <RcIcon
                  symbol={ExternalLink}
                  className={styles.externalLinkIcon}
                  color="neutral.f06"
                />
              )}
            </div>
            <div
              className={styles.frontText}
              title={item.frontText}
              // @ts-ignore
              STYLE={features.item.frontText}
            >
              <MultipleLineEllipsis linesToDisplay={2} lineHeightPx={24}>
                {item.frontText}
              </MultipleLineEllipsis>
            </div>
            {appRatingEnabled && ratingInfo && (
              <RatingContainer data-test-automation-id="feature-item-rating-wrapper">
                <RatingInfo
                  rating={ratingInfo.overallRating}
                  ratingCount={ratingInfo.voteCount}
                  translation={translation.ratingsAndReviews}
                />
              </RatingContainer>
            )}
          </div>
        </div>
      </FeatureItemContainer>
    </div>
  );
}

export { FeatureItem };
