import * as _ from 'lodash';
import { get } from '@apw/core/api/api.service';
import { IpaasPlatformInterface } from '@apw/modules/search/ipaasSearch/ipaasPlatform.interface';
import { IpaasPlatforms } from '@apw/modules/search/ipaasSearch/ipaasPlatforms';
import {
  IpaasRawDataResponseInterface,
  IpaasRawDataItemInterface,
  IpaasListResponseInterface,
} from '@apw/modules/search/ipaasSearch/typings';
import { rootStore } from '@apw/stores';
import { IpaasListItem, PagingInterface } from '@apw/types';

const version = _.get(window, 'RCAPW.VERSION');
const perPage = 9;
const viewAllIntegrationsPaasCode = 'ipaas/*';
let ipaasData = [] as IpaasListItem[];
let cacheBuster = version;

const NETSTORAGE_URI_PREFIX = 'https://netstorage.ringcentral.com/dpw';
const DPW_S3_URI_PREFIX = 'https://assets-developers.ringcentral.com/dpw';

export const searchIpaas = (
  searchText: string,
  brandId: string,
  page: number,
) => {
  if (ipaasData.length > 0) {
    return Promise.resolve(filterData(searchText, brandId, page));
  }
  return fetchData().then((data) => {
    ipaasData = transformRawData(data.items);
    cacheBuster = data.v;
    return Promise.resolve(filterData(searchText, brandId, page));
  });
};

export const getAppIconUrl = (platform: string, fileName): string => {
  return `${NETSTORAGE_URI_PREFIX}/app-gallery/ipaas/${platform}/${fileName}?v=${cacheBuster}`;
};

export const getPlatformNameById = (platformId: string) => {
  return Object.keys(IpaasPlatforms)
    .map((key) => IpaasPlatforms[key])
    .find((platform: IpaasPlatformInterface) => platform.id === platformId)
    .name;
};

const fetchData = () => {
  const endpoint = `${DPW_S3_URI_PREFIX}/api-reference/specs/app-gallery/ipaas.json?v=${version}`;
  return get<IpaasRawDataResponseInterface>(endpoint)
    .then((res) => res)
    .catch(() => {
      return {
        paging: {},
        v: '',
        items: [],
      };
    });
};

const transformRawData = (
  rawData: IpaasRawDataItemInterface[],
): IpaasListItem[] => {
  return _.compact(
    rawData.map((raw: IpaasRawDataItemInterface) => alignFormat(raw)),
  );
};

const getPlatformConfig = (
  platformId: string,
): IpaasPlatformInterface | undefined => {
  return _.first(
    _.filter(IpaasPlatforms, (platformConfig: IpaasPlatformInterface) => {
      return platformConfig.id === platformId;
    }),
  );
};

const getCamelCase = (str) => {
  return str.replace(/-([a-z])/g, function (all, i) {
    return i.toUpperCase();
  });
};

const alignFormat = (
  unaligned: IpaasRawDataItemInterface,
): IpaasListItem | undefined => {
  let aligned;

  try {
    const extraStyle = {};
    if (unaligned.tp.es) {
      const styles = unaligned.tp.es.split(';');
      styles.forEach((style) => {
        const stylePair = style.split(':');
        extraStyle[getCamelCase(stylePair[0])] = getCamelCase(stylePair[1]);
      });
    }
    aligned = {
      rcAppName: unaligned.rc,
      thirdPartyAppName: unaligned.tp.n,
      logo: getAppIconUrl(unaligned.ip, unaligned.tp.i),
      publisher: getPlatformNameById(unaligned.ip),
      platform: unaligned.ip,
      url: unaligned.iu,
      extraStyle,
      squareIconWithSpacing: getPlatformConfig(unaligned.ip)
        ?.squareIconWithSpacing,
    } as IpaasListItem;
  } catch (err) {
    // TODO error handler
  }

  return aligned;
};

const filterData = (
  searchText: string,
  brandId: string,
  page: number,
): IpaasListResponseInterface => {
  const result = _.filter(ipaasData, (item: IpaasListItem) => {
    // If the search text fully matches the `viewAllIntegrationsPassCode`, it will show all IPaaS list items.
    if (searchText === viewAllIntegrationsPaasCode) {
      return true;
    }

    if (!rootStore.compatibilitiesStore.isRCBrand(brandId)) {
      return false;
    }
    return (
      !_.isEmpty(searchText) &&
      _.includes(item.thirdPartyAppName.toLowerCase(), searchText.toLowerCase())
    );
  });

  const items = result.slice((page - 1) * perPage, page * perPage);
  const paging = buildPaging(page, result);

  return {
    items,
    paging,
  } as IpaasListResponseInterface;
};

const buildPaging = (page: number, result: IpaasListItem[]) => {
  return {
    page,
    perPage,
    pageStart: 1,
    pageEnd: perPage,
    totalElements: result.length,
    totalPages: Math.ceil(result.length / perPage),
  } as PagingInterface;
};
