import { filter } from 'lodash';
import { IProduct, Product, ProductGroup } from '@apw/types/product';

export type ProductConfig = {
  [id in Product]: IProduct;
};

export const allProductsItem = {
  id: 'All',
  displayName: 'All products',
  group: ProductGroup.PRIMARY,
} as unknown as IProduct;

export const productConfig: ProductConfig = {
  // Primary products

  [Product.MESSAGE]: {
    id: Product.MESSAGE,
    displayName: 'Team Messaging',
    group: ProductGroup.PRIMARY,
  },
  [Product.VIDEO]: {
    id: Product.VIDEO,
    displayName: 'Video',
    group: ProductGroup.PRIMARY,
  },
  [Product.WEBINAR]: {
    id: Product.WEBINAR,
    displayName: 'Webinar',
    group: ProductGroup.PRIMARY,
  },
  [Product.EVENTS]: {
    id: Product.EVENTS,
    displayName: 'Events',
    group: ProductGroup.PRIMARY,
  },
  [Product.PHONE]: {
    id: Product.PHONE,
    displayName: 'Phone',
    group: ProductGroup.PRIMARY,
  },

  // Secondary products

  [Product.CONTACT_CENTER]: {
    id: Product.CONTACT_CENTER,
    displayName: 'Contact Center',
    group: ProductGroup.SECONDARY,
  },
  [Product.ENGAGE_DIGITAL]: {
    id: Product.ENGAGE_DIGITAL,
    displayName: 'RingCX Digital',
    group: ProductGroup.SECONDARY,
  },
  [Product.ENGAGE_VOICE]: {
    id: Product.ENGAGE_VOICE,
    displayName: 'RingCX Voice',
    group: ProductGroup.SECONDARY,
  },
  [Product.MEETINGS]: {
    id: Product.MEETINGS,
    displayName: 'Meetings',
    group: ProductGroup.SECONDARY,
  },
  [Product.SERVICE_WEB]: {
    id: Product.SERVICE_WEB,
    displayName: 'Service Web',
    group: ProductGroup.SECONDARY,
  },
};

export const primaryProducts = filter(
  productConfig,
  (product) => product.group === ProductGroup.PRIMARY,
);
export const secondaryProducts = filter(
  productConfig,
  (product) => product.group === ProductGroup.SECONDARY,
);

export const getProductByIdOrName = (
  searchKey: string,
): IProduct | undefined => {
  let product = productConfig[searchKey];

  if (!product) {
    product = Object.keys(productConfig)
      .map((key) => productConfig[key])
      .find((p) => p.displayName === searchKey);
  }

  return product;
};
