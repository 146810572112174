import { uniqBy } from 'lodash';
import { action, comparer, computed, observable, reaction } from 'mobx';
import { IProcessedProfile } from '@apw/components/profileDetail/index';
import {
  IReviewItem,
  ReviewStatus,
} from '@apw/components/ratingAndReview/typings/reviewItem.interface';
import { mixpanel, transport } from '@apw/core';
import { platformTypeToAppType } from '@apw/core/application.service';
import { MediumEnum } from '@apw/core/tracker/types';
import { rootStore } from '@apw/stores';
import { BaseViewModel, PagingInterface } from '@apw/types';

export class ProfileViewModel extends BaseViewModel<void> {
  @observable
  profile: IProcessedProfile | null | undefined;

  @observable
  myReviewItem: IReviewItem | null = null;

  @observable
  isPublishedReviewItemsLoading = false;

  @observable
  publishedReviewItems: IReviewItem[] = [];

  @observable
  pagingForReviews: PagingInterface;

  @observable
  ratingPopupOpen = false;

  @computed
  get totalNumberForReviews() {
    const publishedReviewItemsCount = this.pagingForReviews?.totalElements || 0;

    return this.myPendingReviewItem
      ? publishedReviewItemsCount + 1
      : publishedReviewItemsCount;
  }

  @computed
  get myPendingReviewItem() {
    return this.myReviewItem?.status === ReviewStatus.Pending
      ? this.myReviewItem
      : null;
  }

  @computed
  get myPublishedReviewItem() {
    return this.publishedReviewItems.find((item) => item.myReview);
  }

  private get applicationId() {
    return this.profile?.appId;
  }

  private get rcExtId() {
    return rootStore.userStore.user?.extensionId;
  }

  private disposers: Function[] = [];

  constructor() {
    super();
    this.init();
  }

  @action.bound
  openRatingPopup() {
    this.ratingPopupOpen = true;
  }

  @action.bound
  closeRatingPopup() {
    this.ratingPopupOpen = false;
  }

  @action.bound
  setProfile(profile: IProcessedProfile | null | undefined) {
    this.profile = profile;
  }

  @action.bound
  setMyReviewItem(reviewItem: IReviewItem | null) {
    this.myReviewItem = reviewItem;
  }

  @action.bound
  setIsPublishedReviewItemsLoading(isLoading: boolean) {
    this.isPublishedReviewItemsLoading = isLoading;
  }

  @action.bound
  setPublishedReviewItems(reviewItems: IReviewItem[]) {
    this.publishedReviewItems = reviewItems;
  }

  @action.bound
  setPagingForReviews(paging: PagingInterface) {
    this.pagingForReviews = paging;
  }

  deleteMyReviewItem = (reviewItem: IReviewItem) => {
    return transport.deleteMyReviewItem(this.applicationId!).then(() => {
      rootStore.myReviewsStore.onItemDeleted(
        this.rcExtId!,
        this.applicationId!,
      );

      this.removeMyPublishedReviewFromList();

      this.trackRatingStateChange(reviewItem, ReviewStatus.Deleted);
    });
  };

  trackRatingStateChange = (
    reviewItem: IReviewItem,
    newState: ReviewStatus,
  ) => {
    if (!this.profile) {
      return;
    }

    mixpanel.trackRatingStateChange({
      appId: this.profile.appId,
      appName: this.profile.applicationName,
      appType: platformTypeToAppType(this.profile.applicationType),
      appDisplayName: this.profile.applicationDisplayName,
      brand: this.profile.brand,
      orgId: this.profile.orgId,
      orgType: this.profile.orgType,
      profileName: this.profile.appName,
      medium: MediumEnum.WEB,
      requestDate: reviewItem.creationTime,
      modifiedDate: new Date().toISOString(),
      oldState: reviewItem.status,
      newState,
      overallRating: reviewItem.overallRating,
      valueRating: reviewItem.valueRating,
      convenienceRating: reviewItem.convenienceRating,
      featureRating: reviewItem.featureRating,
      supportRating: reviewItem.supportRating,
      reviews: reviewItem.content,
    });
  };

  removeMyPublishedReviewFromList = () => {
    const newPublishedReviewItems = this.publishedReviewItems.filter(
      (reviewItem) => !reviewItem.myReview,
    );

    const originalCount = this.publishedReviewItems.length;
    const newCount = newPublishedReviewItems.length;
    const deduction = originalCount - newCount;

    this.setPublishedReviewItems(newPublishedReviewItems);

    this.setPagingForReviews({
      ...this.pagingForReviews,
      totalElements: this.pagingForReviews.totalElements - deduction,
    });
  };

  loadPublishedReviewItems = (page = 1) => {
    this.setIsPublishedReviewItemsLoading(true);

    transport
      .fetchPublishedReviewItems(this.applicationId!, page)
      .then((res) => {
        const records = res.records || [];

        this.setPagingForReviews(res.paging);

        if (res.paging?.page > 1) {
          const items = uniqBy(
            [...this.publishedReviewItems, ...records],
            (item) => item.id,
          );

          const extendedPublishedReviewItems =
            this.extendPublishedReviewItems(items);

          this.setPublishedReviewItems(extendedPublishedReviewItems);

          return;
        }

        const extendedPublishedReviewItems =
          this.extendPublishedReviewItems(records);

        this.setPublishedReviewItems(extendedPublishedReviewItems);
      })
      .finally(() => {
        this.setIsPublishedReviewItemsLoading(false);
      });
  };

  extendPublishedReviewItems = (reviewItems: IReviewItem[]) => {
    return reviewItems.map((item) => ({
      ...item,
      status: ReviewStatus.Approved,
    }));
  };

  dispose() {
    while (this.disposers.length > 0) {
      const dispose = this.disposers.pop();

      if (dispose) {
        dispose();
      }
    }
  }

  private init() {
    reaction(
      () => this.applicationId,
      (applicationId) => {
        if (applicationId) {
          this.dispose();
          this.setup();
        }
      },
    );
  }

  private setup() {
    this.setupLoginStatusReactionForMyReviews();
    this.setupDataSyncReactionForMyReview();

    rootStore.userStore.onResolved().then(() => {
      this.setupLoginStatusReactionForPublishedReviews();
    });
  }

  private setupLoginStatusReactionForMyReviews() {
    const disposer = reaction(
      () => rootStore.userStore.isLoggedIn,
      (isLoggedIn) => {
        if (!isLoggedIn) {
          this.setMyReviewItem(null);
          return;
        }

        rootStore.myReviewsStore
          .loadItem(this.rcExtId!, this.applicationId!)
          .then((reviewItem) => {
            this.setMyReviewItem(reviewItem);
          });
      },
      {
        fireImmediately: true,
      },
    );

    this.disposers.push(disposer);
  }

  private setupDataSyncReactionForMyReview() {
    const disposer = reaction(
      () =>
        rootStore.myReviewsStore.getItemFromCache(
          this.rcExtId!,
          this.applicationId!,
        ),
      (myReviewItem) => {
        if (!rootStore.userStore.isLoggedIn) {
          this.setMyReviewItem(null);
          return;
        }

        this.setMyReviewItem(myReviewItem);
      },
    );

    this.disposers.push(disposer);
  }

  private setupLoginStatusReactionForPublishedReviews() {
    const disposer = reaction(
      () => ({
        isLoggedIn: rootStore.userStore.isLoggedIn,
        isAppRatingEnabled: this.profile?.appRatingEnabled,
      }),
      ({ isAppRatingEnabled }) => {
        if (!isAppRatingEnabled) {
          return;
        }

        this.loadPublishedReviewItems(1);

        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 300);
      },
      {
        fireImmediately: true,
        equals: comparer.structural,
      },
    );

    this.disposers.push(disposer);
  }
}
