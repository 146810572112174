import { RcTypography, styled } from '@ringcentral/juno';
import { focusOutline } from '../../sc';

export const ContentWrapperShadow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.3s;
  pointer-events: none;
  border-radius: 8px;
`;

export const InstallTrigger = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 2;
  right: 15px;
  top: 15px;
`;

export const ContentWrapper = styled(RcTypography)`
  display: block;
  height: 100%;
  border-radius: 8px;

  &:focus-visible {
    ${focusOutline};

    & ~ ${InstallTrigger} {
      opacity: 1;
    }

    & ~ ${ContentWrapperShadow} {
      display: block;
    }
  }
`;

export const RatingWrapper = styled.div`
  margin-top: auto;
  padding-top: 12px;
`;
