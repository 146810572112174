import { createContext, CSSProperties, MutableRefObject } from 'react';
import { ContentLayout } from '@apw/lib/shared/responsive';
import { ICollectionBlock, IContentBlockTypography } from '@apw/lib/types';

interface IHeaderBlockToken {
  title: IContentBlockTypography;
  desc: IContentBlockTypography;
}

interface ICollectionBlockToken {
  title: IContentBlockTypography;
  subTitle: IContentBlockTypography;
  item: {
    name: IContentBlockTypography;
    publisher: IContentBlockTypography;
    premierPartner: IContentBlockTypography;
    certifiedBadge: string;
    shortDescription: IContentBlockTypography;
    backgroundColor: string;
    backgroundHoverColor: string;
    borderColor: string;
    col: 'tileCol4' | 'tileCol3' | 'tileCol2' | 'tileCol1' | string;
  };
}

interface IFeatureBlockToken {
  heading: IContentBlockTypography;
  subHeading: IContentBlockTypography;
  item: {
    title: string;
    frontText: string;
  };
  container: string;
  mask: string;
  content: string;
  sponsored: CSSProperties;
}

interface IHtmlBlockToken {
  htmlContent: IContentBlockTypography;
}

interface IPageTranslation {
  certifiedBadge: string;
  sponsored: string;
  premierPartner: string;
  getAppLogo: (appName: string) => string;
  getImage: (name: string) => string;
  ratingsAndReviews: IRatingsAndReviews;
}

interface IRatingsAndReviews {
  countLabel: {
    singular: string;
    plural: string;
  };
}

type IOnScrollOverCollectionBlock = (
  block: ICollectionBlock,
  index: number,
) => void;

export interface IPageContentContext {
  styleTokens: {
    headerBlock: IHeaderBlockToken;
    collectionBlock: ICollectionBlockToken;
    features: IFeatureBlockToken;
    htmlBlock: IHtmlBlockToken;
  };
  isInternalLink: (url: string) => boolean;
  translation: IPageTranslation;
  onScrollOverCollectionBlock: IOnScrollOverCollectionBlock;
  scrollContainerRef: MutableRefObject<HTMLElement | null>;
  contentLayout: ContentLayout;
}

const defaultTypography = {
  variant: '',
  color: '',
  backgroundColor: '',
} as unknown as IContentBlockTypography;

export const PageContentContext = createContext<IPageContentContext>({
  styleTokens: {
    headerBlock: {
      title: defaultTypography,
      desc: defaultTypography,
    },
    collectionBlock: {
      title: defaultTypography,
      subTitle: defaultTypography,
      item: {
        name: defaultTypography,
        publisher: defaultTypography,
        premierPartner: defaultTypography,
        certifiedBadge: '#32773B',
        shortDescription: defaultTypography,
        backgroundColor: '',
        backgroundHoverColor: '',
        borderColor: '',
        col: '',
      },
    },
    features: {
      heading: defaultTypography,
      subHeading: defaultTypography,
      item: {
        title: '',
        frontText: '',
      },
      container: '',
      mask: '',
      content: '',
      sponsored: {},
    },
    htmlBlock: {
      htmlContent: defaultTypography,
    },
  },
  isInternalLink: () => false,
  translation: {
    certifiedBadge: 'CERTIFIED',
    sponsored: 'Sponsored',
    premierPartner: 'PREMIER PARTNER',
    getAppLogo: (appName) => `${appName} app logo`,
    getImage: (name) => `${name} image`,
    ratingsAndReviews: {
      countLabel: {
        singular: 'rating',
        plural: 'ratings',
      },
    },
  },
  onScrollOverCollectionBlock: () => {},
  scrollContainerRef: { current: null },
  contentLayout: ContentLayout.THREE_COLUMNS,
});
